import styled from "styled-components"

export const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding-top: 120px;
  padding-bottom: 20px;

  h1 {
    color: #2ecb77;
    text-transform: uppercase;
    font-size: 38px;
    font-weight: 600;
  }
  p {
    font-size: 18px;
    line-height: 25px;
    color: #021737;
    width: 100%;
  }

  .vertical_center {
    align-self: center;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
`
export const OfferWrapper = styled.div`
  h2 {
    color: #003283;
    font-size: 30px;
    font-weight: 600;
  }
  p {
    font-size: 18px;
    line-height: 25px;
    color: #021737;
    width: 100%;
  }
  .card-body {
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 var(--bluish-green);
  }
  .flip-row {
    margin: 15px 0;
  }

  .flip-card {
    background-color: transparent;
    width: 100%;
    min-height: 200px;
    height: 100%;
    perspective: 1000px;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-card-front {
    box-shadow: 1px 6px 10px -6px #003283;
    border-radius: 8px;
    border-top: 1px solid #0032832e;
    padding: 25px 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
  }

  .flip-card-front svg {
    color: #28c973;
    font-size: 50px;
    margin-bottom: 10px;
  }

  .flip-card-back {
    background-color: #003283;
    color: #f1f1f1;
    transform: rotateY(180deg);
    border-radius: 8px;
    padding: 25px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flip-card-front h3 {
    color: #021737;
    font-size: 22px;
    font-weight: 500;
  }

  .flip-card-back p {
    color: white;
    font-size: 14px;
    margin: 0;
  }
`
export const FeatureWrapper = styled.div`
padding: 50px 0;
  h2 {
    color: #003283;
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
  }
  img{
    filter: hue-rotate(233deg);
   
  }
`
export const BenefitWrapper = styled.div`
padding-bottom: 80px;
h2 {
  color: #003283;
  font-size: 30px;
  font-weight: 600;
  text-transform: uppercase;
}
ul{
    padding:0;
    margin:0;
    margin-top: 40px;
    li{
        display: inline-flex;
        width: 48%;
        vertical-align: top;
        min-height: 92px;
        padding: 10px 2%;
        background: #e9edf4;
        margin: 10px 10px;
        border-radius: 10px;
        align-items: center;

        p{
            font-size: 16px;
        }
        @media (max-width: 991px) {
          width:100%;
        }
    }
}
`
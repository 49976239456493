import React, { useEffect } from "react"
import { FeatureWrapper } from "./Dmarc.styles"
import { Container, Col, Row } from "react-bootstrap"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import FeatureImage from "../../images/dmarc360-platform-feature.png"

function PlatformFeature() {
    const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

    return (
        <FeatureWrapper>
            <Container>
                <Row>
                    <Col>
                    <motion.div
              ref={ref}
              animate={controls}
              initial="hidden"
              variants={{
                visible: { opacity: 1, y: 0, transition: { duration: 1 } },
                hidden: { opacity: 0, y: 50 },
              }}
            >
                <h2>Platform Feature</h2>
                <img src={FeatureImage}></img>
            </motion.div>
                    </Col>
                </Row>
            </Container>
            
        </FeatureWrapper>
    )
}

export default PlatformFeature

import React, { useEffect } from "react"
import { BenefitWrapper } from "./Dmarc.styles"
import { Container, Col, Row } from "react-bootstrap"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import Cards from "../UI/Cards"

const container = {
    hidden: { opacity: 1, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.4
      }
    }
  };
  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

export default function DmarcBenefits() {
    const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <BenefitWrapper>
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <motion.div>
              <motion.h2
              initial="{ y: 20, opacity: 0 }"
              animate="{
                y: 0,
                opacity: 1
              }">BENEFITS</motion.h2>
              
              
              <motion.ul class="stack-ul"
              ref={ref}
              animate={controls}
              variants={container}
              initial="hidden">
                <motion.li variants={item} ><p>Stop illegitimate use of your valid domains .</p></motion.li>
                <motion.li variants={item} ><p>Prevent Enterprise spear phishing & other attack variants.</p></motion.li>
                <motion.li variants={item} > <p>Detect misconfigurations of SPF and DKIM. </p> </motion.li>
                <motion.li variants={item} ><p>Obtain an inventory of all email senders using the valid email domain. </p></motion.li>
                <motion.li variants={item} ><p>Increase email deliverability.  </p></motion.li>
                <motion.li variants={item} ><p>Gain insight through reports on all domain activity.</p></motion.li>
              </motion.ul>
              
            </motion.div>
          </Col>
        </Row>
      </Container>
    </BenefitWrapper>
  )
}

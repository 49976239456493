import * as React from "react"
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';

import Layout from "../components/Layout/Layout"
import Seo from "../components/seo";
import Dmarc from "../components/Dmarc";
import CallToAction from "../components/CallToAction";
import DmarcOffers from "../components/Dmarc/DmarcService";
import PlatformFeature from "../components/Dmarc/PlatformFeature";
import DmarcBenefits from "../components/Dmarc/DmarcBenefits";


const IndexPage = () => (
  <Layout>
    <Seo title="DMARC360" />
    <Dmarc/>
    <DmarcOffers/>
    <PlatformFeature/>
    <DmarcBenefits/>
    <CallToAction title="SPOOF PROOF your domain with the implementation of DMARC" linktext="Contact Us" url="/contact"/>
  </Layout>
)

export default IndexPage

import React, { useEffect } from "react"
import { MainWrapper } from "./Dmarc.styles"
import Banner from "../../images/envelop_fly.png"
import { Container, Col, Row } from "react-bootstrap"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
function Dmarc() {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <MainWrapper>
      <Container>
        <Row>
          <Col lg={6} md={6} sm={12} xs={12}>
            <motion.div
              class="vertical_center banner-text"
              ref={ref}
              animate={controls}
              initial="hidden"
              variants={{
                visible: { opacity: 1, x: 0, transition: { duration: 1 } },
                hidden: { opacity: 0, x: -50 },
              }}
            >
              <h1>Protecting Your Brand & Clients From Email Impersonators</h1>
              <p>
                <strong>DMARC360</strong> is an email deliverability &
                intelligence platform with primary focus is to enhance email
                deliverability by assisting in the implementation of DMARC on
                organizations’ domains.
              </p>
              <p>
                Additionally, a step-by-step process to recreate an identified
                vulnerability is also included to guide security practitioners
                through the testing process. As an official partner with the{" "}
                <strong>Global Cyber Alliance</strong>, DMARC360 aims to
                continuously promote and spread awareness on the importance of
                DMARC and its implementation.
              </p>
            </motion.div>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <img src={Banner} />
          </Col>
        </Row>
      </Container>
    </MainWrapper>
  )
}

export default Dmarc

import React, { useEffect } from "react"
import { OfferWrapper } from "./Dmarc.styles"
import { Container, Col, Row } from "react-bootstrap"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

import { FaLaptop } from "@react-icons/all-files/fa/FaLaptop"
import { FaCogs } from "@react-icons/all-files/fa/FaCogs"
import { FaHeadset } from "@react-icons/all-files/fa/FaHeadset"


function DmarcOffers() {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <OfferWrapper>
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <motion.div
              ref={ref}
              animate={controls}
              initial="hidden"
              variants={{
                visible: { opacity: 1, y: 0, transition: { duration: 1 } },
                hidden: { opacity: 0, y: 50 },
              }}
            >
              <h2>SERVICES</h2>
              <p>We collect DMARC data, analyze email traffic patterns and sources, rapidly deploy/enhance email authentication mechanisms, and monitor email domains.</p>
            </motion.div>
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={4} sm={12} xs={12} className="flip-row">
            <div className="flip-card ">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <FaLaptop/>
                  <h3>Platform</h3>
                </div>

                <div className="flip-card-back">
                  <p>
                  Analyze your email traffic patterns and sources, rapidly deploy email authentication protocols and monitor your email domains.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="flip-row">
            <div className="flip-card ">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <FaCogs/>
                  <h3>Implementation</h3>
                </div>

                <div className="flip-card-back">
                  <p>
                  Configuration assistance of the DMARC record and Taking your policy from “None” all the way through to “Reject” resulting in a DMARC compliant domain.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12} className="flip-row">
            <div className="flip-card ">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <FaHeadset/>
                  <h3>Support</h3>
                </div>

                <div className="flip-card-back">
                  <p>
                  24/7 support from our dedicated team of analysts to assist in DMARC implementation, configuration, analysis and any other queries you may have.
                  </p>
                </div>
              </div>
            </div>
          </Col>
         
        </Row>
      </Container>
    </OfferWrapper>
  )
}

export default DmarcOffers
